export const BRANCH_SPLIT_KEY = '_';

export const hasVersionBranch = (str = ''): boolean => {
  return str.includes(BRANCH_SPLIT_KEY);
};

export const parseVersionBranch = (
  str = '',
): {
  version: string;
  branch?: string;
} => {
  const [version, ...rest] = str.split(BRANCH_SPLIT_KEY);
  // handles an edge case with mulitple _ in our string.
  const branch = rest.join(BRANCH_SPLIT_KEY);
  return { version, branch };
};
